<template>
    <div class="sup_content media">
        <div class="sup_page_heading">
            <h2>Media</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add New Media'" @click="openMediaForm = true" />
            <Button icon="pi pi-trash" :label="bulKdelete.btnLabel" class="p-button-sm p-button-rounded p-button-danger p-ml-2" v-tooltip="'Bulk Delete Images'" @click="bulKdelete.state = true" />
        </div>
        <div class="sup_inner_content">
            <div class="sup_media_bredcrumbs">
                <ul>
                    <li v-for="(item, i) in mediaData.bredcrumbs" :class="[i === 0 ? 'root' : '']" :key="i">
                        <span :data-link="item.link" @click="fetchMediaItems(item.link)">{{item.name}}</span>
                    </li>
                </ul>
            </div>
            <div class="sup_media_wrap">
                <div v-if="mediaData.folders.length > 0" class="sup_folders_container">
                    <h3>Folders</h3>
                    <div class="sup_media_folders">
                        <div class="sup_media_folder" v-for="folder in mediaData.folders" :key="folder" @click="fetchMediaItems(folder.prefix)">
                            <img class="sup_folder_icon" src="../../../assets/images/folder_icon.svg" alt="Folder">
                            <span class="sup_media_folder_name">{{folder.name}}</span>
                            <span class="sup_folder_actions">
                                <i class="pi pi-pencil pi-action-primary" v-tooltip="'Rename'"></i>
                                <i class="pi pi-trash pi-action-danger" v-tooltip="'Delete'"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="mediaData.images.length > 0" class="sup_images_container">
                    <h3>Images</h3>
                    <div class="sup_media_images">
                        <div class="sup_media_image" v-for="(image, i) in mediaData.images" :key="i" >
                            <div class="sup_media_image_inner" @click="viewImageDetail(image)">
                                <figure>
                                    <img :src="image.image" :alt="image.imageName">
                                </figure>
                                <span class="sup_media_image_name">{{image.imageName}}</span>
                            </div>
                            <span class="sup_media_actions">
                                <i class="pi pi-copy pi-action-primary" v-tooltip="'Copy Url'" @click="copyImageUrl($event, 'noPopUp')">
                                    <input type="text" :value="image.image" readonly />
                                </i>
                                <i class="pi pi-trash pi-action-danger" v-tooltip="'Delete'" @click="deleteImage(image.imageName, 'noPopUp')"></i>
                            </span>
                            <span v-if="bulKdelete.state" class="sup_check_to_delete">
                                <!-- <Checkbox id="binary" v-model="false" :binary="false" /> -->
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="mediaData.utilities.length > 0" class="sup_Utility_container">
                    <h3>Other Files</h3>
                    <div class="sup_media_images">
                        <div class="sup_media_image" v-for="utility in mediaData.utilities" :key="utility">
                            <i class="pi pi-folder"></i>
                            <span class="sup_media_image_name">{{utility.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--- Add Media Modal Start --->
        <Dialog header="Add New Media" v-model:visible="openMediaForm" :modal="true" :style="{width: '600px'}">
            <TabView>
                <TabPanel header="Create Folder">
                    <form @submit.prevent="createFolderandUpload('createFolder', null)">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <label for="folder_create">Folder Name<span class="text-danger">*</span></label>
                                <InputText v-model="addFolder" id="folder_create" type="text" />
                                <small class="p-invalid sup_text_danger" v-if="addFolderError">Folder name missing.</small>
                            </div>
                        </div>
                        <Button type="submit" label="Create Folder" class="btn-info sup_submit" />
                    </form>
                </TabPanel>
                <TabPanel header="Upload Image">
                    <FileUpload name="demo[]" :multiple="true" chooseLabel="Browse" :customUpload="true" @uploader="createFolderandUpload('upload', $event)" />
                </TabPanel>
            </TabView>
        </Dialog>
        <!--- Add Media Modal End --->
        <!--- image view --->
        <Dialog header="Image" v-model:visible="imageModal.state" :modal="true" :style="{width: '600px'}">
            <div class="sup_image_detail_view">
                <figure>
                    <img :src="imageModal.mdata.image" :alt="imageModal.mdata.imageName">
                </figure>
                <div class="sup_image_elm sup_image_name">
                    <strong>Name:</strong> <span>{{imageModal.mdata.imageName}}</span>
                </div>
                <div class="sup_image_elm sup_image_path">
                    <strong>Url:</strong>
                    <span class="sup_copy_image_url" :data-license="imageModal.mdata.image"><input type="text" :value="imageModal.mdata.image" readonly /><i class="pi pi-copy" @click="copyImageUrl($event, 'popUp')" v-tooltip="'Copy Url!'"></i></span>
                </div>
                <Button type="button" label="Delete Image" class="p-button p-button-danger p-mt-3" @click="deleteImage(imageModal.mdata.imageName, 'popUp')" />
            </div>
        </Dialog>
        <!--- image view --->
    </div>
</template>

<script>
import { onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { allMediaItems, mediaFolderCreate } from '../../../config/appUrls';
import { apiKey } from '../../../config/constant';
import { capitalizeCase } from '../../../helper/utility/utility';

export default {
    name: 'Dashboard',
    components: {},
    setup(){
        const store = useStore();

        const mediaData = reactive({
            folders: [],
            images: [],
            utilities: [], 
            prefix: null,
            currentPrefix: "",
            bredcrumbs: [],
            rootLink: {
                name: "S3 Root",
                link: ""
            }
        });

        const openMediaForm = ref(false);

        const imageModal = reactive({
            state: false,
            mdata: ''
        })

        const addFolder = ref("");
        const addFolderError = ref(false);

        const bulKdelete = reactive({
            state: false,
            btnLabel: '',
            deleteItems: []
        });

        onBeforeMount(()=>{
            fetchMediaItems();
        });

        const fetchMediaItems = async(newPf = "") => {
            //Bredcrumb
            let linkArr = newPf.split("/");
            linkArr.pop();
            let tempArr3 = [];
            let link = "";
            for(let br = 0; br < linkArr.length; br++){
                link = link  + linkArr[br] + "/";
                tempArr3.push({
                    name: linkArr[br],
                    link: link
                })
            }
            mediaData.bredcrumbs = [];
            mediaData.bredcrumbs.push(mediaData.rootLink, ...tempArr3);

            mediaData.currentPrefix = newPf;

            //Api call folder fetch
            if(newPf === "") mediaData.prefix
            else mediaData.prefix = newPf;
            try{
                const response = await axios.get(allMediaItems,{
                    params: {
                        prefix: mediaData.prefix
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                //console.log(response.data.data);
                mediaData.prefix = "";
                mediaData.folders = [];
                mediaData.images = [];
                mediaData.utilities = [];
                for(let key in response.data.data){
                    if(response.data.data[key].Prefix){
                        let prefixLength = response.data.data[key].Prefix.slice(newPf.length, response.data.data[key].Prefix.length);
                        mediaData.folders.push({
                            name: capitalizeCase(prefixLength.split("/")[0]),
                            prefix: response.data.data[key].Prefix
                        });
                    } else if(response.data.data[key].image) {
                        mediaData.images.push(response.data.data[key]);
                    } else {
                        mediaData.utilities.push(response.data.data[key]);
                    }
                }
                
            }catch(err){
                console.log(err);
            }
        }

        /**
         * This Function used Create Folder/Upload images
         * @param {String} state
         * @param {String} event
         * @returns {Function} Nested call fetchMediaItems
         */
        const createFolderandUpload = async(state, event) =>{
            if(state === "createFolder"){
                console.log(addFolder.value, mediaFolderCreate);
                if(addFolder.value === ""){
                    addFolderError.value = true
                } else {
                    addFolderError.value = false
                }
                try{
                    const response = await axios.post(mediaFolderCreate, {
                        prefix: mediaData.currentPrefix,
                        folder: addFolder.value
                    },{
                        headers:  {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }
                    });
                    console.log(response);
                    if(response.data.status === 200){
                        setTimeout(()=>{
                            openMediaForm.value = false;
                            fetchMediaItems(mediaData.currentPrefix);
                            addFolder.value = "";
                        },1000);
                    }
                } catch(err){
                    console.error(err);
                }

            } else if(state === "upload"){
                console.log(event.files);
                try{
                    let formData = new FormData();
                    for(let i = 0; i< event.files.length; i++){
                        formData.append('uploadedImages', event.files[i]);
                    }
                    formData.append("prefix", mediaData.currentPrefix);
                    console.log(formData);
                    const options = {
                        method: 'post',
                        url: allMediaItems,
                        data: formData,
                        headers: {"Content-Type": "multipart/form-data", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            // transform the data
                            return data;
                        }]
                    };
                    const res = await axios(options);
                    if(res.data.status === 200){
                        setTimeout(()=>{
                            openMediaForm.value = false;
                            fetchMediaItems(mediaData.currentPrefix);
                        },1000);
                    }
                } catch(err){
                    console.error(err);
                }
            }
        }


        const viewImageDetail = (data) => {
            console.log(data);
            imageModal.state = true;
            imageModal.mdata = data;
        }

        const copyImageUrl = (e, state) => {
            let getEle;
            if(state === "popUp"){
                getEle = e.target.previousSibling;
                console.log(getEle);
            } else {
                getEle = e.target.childNodes[0];
                console.log(e, getEle);
            }
            getEle.select();
            document.execCommand("copy");
        }

        const deleteImage = async(imageName, state) => {

            const options = {
                method: 'delete',
                url: allMediaItems,
                data: JSON.stringify({
                    "deleteParam": [{
                        "Key": imageName
                    }],
                    "prefix": mediaData.currentPrefix
                }),
                headers: {"Content-Type": "application/json", 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                transformRequest: [(data) => {
                    // transform the data
                    return data;
                }]
            };
            const res = await axios(options);
            if(res.data.status === 200){
                setTimeout(()=>{
                    if( state === 'popUp') imageModal.state = false;

                    fetchMediaItems(mediaData.currentPrefix);
                },1000);
            }
        }

        return{
            mediaData,
            openMediaForm,
            addFolder,
            addFolderError,
            allMediaItems,
            imageModal,
            fetchMediaItems,
            createFolderandUpload,
            viewImageDetail,
            copyImageUrl,
            deleteImage,
            bulKdelete
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/component/superAdmin/pages/Media';
</style>